import React from "react";
import styles from "../Styles/Home.module.css";
import Navbar from "../components/Navbar";
import Contact from "../components/Contact";

function Chatbot() {
  return (
    <div className={styles.Home}>
      <Navbar />
      <div className={styles.hero_wrapper}>
        <div className={styles.content}>
          <p className={styles.header}> MINDLAB CHATBOT</p>
          <p className={styles.title}>Empowering mind's, Enriching lives</p>
          <p className={styles.text}>
            MindLab remains focused on next generation prevention (Next Gen): on
            the health and well-being of future generations, on systemic
            reforms, and on embracing new frontiers of science.
          </p>
        </div>
      </div>
      <div className={styles.about}>
        <div className={styles.about_content}>
          <p className={styles.title}>Chatbot Description for MINDLAB</p>
          <p className={styles.text}>
            Description: * Name: MINDLAB Assistant * Purpose: To provide
            accessible and confidential mental health support to students at
            Lagos State University. * Features: * 24/7 availability: Students
            can access support anytime, anywhere. * Confidentiality: All
            interactions will be kept private. * Information and Resources: The
            chatbot will provide information on mental health topics, coping
            strategies, and available resources within the university and
            community. * Symptom Assessment: The chatbot can help users identify
            and assess potential mental health concerns. * Referral Service: If
            necessary, the chatbot can connect users with appropriate mental
            health professionals or support groups. Functions: * Information
            Provision: * Answer questions about mental health conditions,
            symptoms, and causes. * Provide information on coping mechanisms,
            stress management techniques, and healthy lifestyle habits. * Offer
            resources like articles, videos, or websites related to mental
            health. * Symptom Assessment: * Conduct self-assessment tools to
            help users identify potential mental health issues. * Provide
            personalized recommendations based on the assessment results. *
            Emotional Support: * Offer empathetic responses and encouragement to
            users experiencing emotional distress. * Provide calming techniques
            and mindfulness exercises. * Referral Service: * Connect users with
            appropriate mental health professionals or support groups within the
            university or community. * Provide information on available services
            and how to access them. * Crisis Intervention: * Identify and
            respond to users in crisis situations. * Provide immediate support
            and guidance, and connect them with emergency services if necessary.
            By providing these functions, MINDLAB Assistant is a valuable tool
            for individuals seeking support and information related to their
            mental health.
          </p>
          <div>
            <p className={styles.text}>Talk to mindlab chatbot</p>
            <button>
              {" "}
              <a href="https://t.me/mindlabngbot">Here</a>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.contact}>
        <Contact />
      </div>
    </div>
  );
}

export default Chatbot;
