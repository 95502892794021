import React from "react";
import styles from "../Styles/ContactUs.module.css";
import insta from "../Assets/icon/insta.svg";
import twitter from "../Assets/icon/twitter.svg"
import facebook from "../Assets/icon/facebook.svg"
import spotify from "../Assets/icon/sportify.svg"
import telegram from "../Assets/icon/telegram.svg"
function Contact() {
  return (
    <div className={styles.contact_section}>
      <h2 className={styles.header}>Contact Us</h2>
      <div className={styles.container}>
        <form className={styles.contact_form}>
          <div className={styles.form_group}>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>

          <div className={styles.form_group}>
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>

          <div className={styles.form_group}>
            <label htmlFor="subject">Subject</label>
            <input type="text" id="subject" name="subject" />
          </div>

          <div className={styles.form_group}>
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="4" required></textarea>
          </div>

          <button type="submit" className={styles.submit_btn}>
            Submit
          </button>
        </form>

        {/* Contact Details */}
        <div className={styles.contact_details}>
          <h3>Get In Touch</h3>
          <p>Email: mindlablasu@gmail.com</p>
          <p>Phone: +234 708 895 0377</p>
          <p>Address: Lasu Main Rd, Ojo, Lagos 102101, Lagos</p>
          <p>
            You can subscribe to our Newsletter{" "}
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSemYRo9X151LPawRjPiTXgegMp0wlj_ZpHAdTHf2KhMxrdN-Q/viewform?usp=pp_url">
              Here
            </a>
          </p>
        </div>
      </div>

      {/* Embedded Map */}
      <div className={styles.map_container}>
        <iframe
          title="organization-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126262.29984647307!2d3.1760734273453957!3d6.556876661228651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8d8a90c7b9d3%3A0xf71dc9d5e0f9ab59!2sLagos%20State%20University!5e0!3m2!1sen!2sng!4v1695296630514!5m2!1sen!2sng"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className={styles.footer}>
        <div className={styles.socials}>
          <span>
            <a href="https://www.instagram.com/p/C_ps7c-I-94/?igsh=MWVkcXhkZTUwaXYwZQ==">
              <img src={insta} alt="" />
            </a>
          </span>
          <span>
            <a href="https://x.com/mindlablasu/status/1829992453975294148?s=46">
              <img src={twitter} alt="" />
            </a>
          </span>
          <span>
            <a href="https://www.facebook.com/profile.php?id=61565342275968&mibextid=LQQJ4d">
              <img src={facebook} alt="" />
            </a>
          </span>
          <span>
            <a href="https://open.spotify.com/show/0EM8ClFQqstgYczcWNlPoR?fbclid=PAZXh0bgNhZW0CMTEAAaZCzn25C0h5lgI_a0GQKgFlfFV-BLv5SBcvWwS2RWoYQx6-nFYz0peWxgY_aem_l_tRELSqBAfVMo9Ijmdt8g">
              <img src={spotify} alt="" />
            </a>
          </span>
          <span>
            <a href="https://t.me/mindlabngbot">
              <img src={telegram} alt="" />
            </a>
          </span>
        </div>
        <div className={styles.footer_msg}>Team Mental Health © 2024</div>
      </div>
    </div>
  );
}

export default Contact;
