import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import client1 from "../Assets/images/img/WhatsApp Image 2024-10-02 at 21.10.35_7bf0b3b2.jpg"
import client2 from "../Assets/images/img/WhatsApp Image 2024-10-02 at 21.29.59_05d2fd8a.jpg"
import client3 from "../Assets/images/img/WhatsApp Image 2024-10-02 at 21.31.33_752a02a1.jpg"

import { Navigation, Pagination } from 'swiper/modules';
import styles from "../Styles/Testimonial.module.css"

function Testimonials() {
  return (
    <div className={styles.testimonial_section}>
    <h2 className={styles.header}>What Our Clients Say</h2>
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      navigation={true}
      modules={[Navigation, Pagination]}
      className={styles.carousel}
    >
      <SwiperSlide>
        <div className={styles.testimonial}>
          <img src={client1} alt=" 1" className={styles.avatar} />
          <p>"The support I received changed my life. I felt understood and guided every step of the way."</p>
          <span>- Hawau Folashade, Client</span>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className={styles.testimonial}>
          <img src={client2} alt=" 2" className={styles.avatar} />
          <p>"Joining the support group helped me overcome my anxiety and meet others with similar struggles."</p>
          <span>Haruna Oluwanifemi, Client</span>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className={styles.testimonial}>
          <img src={client3} alt=" 3" className={styles.avatar} />
          <p>"The therapy sessions were incredibly helpful and life-changing."</p>
          <span>Hassan Kareem Olamilekan, Client</span>
        </div>
      </SwiperSlide>

      
    </Swiper>
  
  </div>
  )
}

export default Testimonials