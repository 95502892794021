import React from "react";
import styles from "../Styles/Home.module.css";
import Navbar from "../components/Navbar";
import Contact from "../components/Contact";

function Newsletter() {
  return (
    <div className={styles.Home}>
      <Navbar />
      <div className={styles.hero_wrapper}>
        <div className={styles.content}>
          <p className={styles.header}> MINDLAB NEWSLETTER</p>
          <p className={styles.title}>Empowering mind's, Enriching lives</p>
          <p className={styles.text}>
            MindLab remains focused on next generation prevention (Next Gen): on
            the health and well-being of future generations, on systemic
            reforms, and on embracing new frontiers of science.
          </p>
         
        </div>
      </div>
      <div className={styles.about}>
        <div className={styles.about_content}>
          <p className={styles.title}>
            MindLab Email Newsletter: Your Mental Health Companion
          </p>
          <p className={styles.text}>
            At MindLab, we believe that mental well-being is just as important
            as physical health. Our email newsletter is designed to be your
            go-to resource for insightful, uplifting, and practical guidance on
            mental health. Whether you’re looking for ways to manage stress,
            improve emotional resilience, or simply learn more about mental
            wellness, our weekly newsletter has you covered. What to Expect in
            the MindLab Newsletter: • Expert Advice: Articles from mental health
            professionals on topics such as anxiety, depression, mindfulness,
            and emotional well-being. • Practical Tips: Easy-to-follow
            techniques for improving your mental health and coping with life’s
            challenges. • Inspiring Stories: Real-life stories of individuals
            who have overcome mental health struggles, offering hope and
            motivation. • Resources: Information on mental health services,
            helplines, and support networks. • Latest Research: Stay informed
            with the latest findings and trends in the mental health field. Our
            mission is to raise awareness, reduce stigma, and promote a culture
            of openness about mental health. Whether you are seeking support for
            yourself or want to help others, MindLab is here to help you on your
            journey to better mental health.
          </p>
          <div>
            <p className={styles.text}>Join the MindLab community and receive expert mental health insights straight to your inbox. Together, we can create a healthier, more mindful world.</p>
            <p className={styles.text}>Subscribe Today!
            </p>
            <button>
              {" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSemYRo9X151LPawRjPiTXgegMp0wlj_ZpHAdTHf2KhMxrdN-Q/viewform?usp=pp_url">
                Here
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.contact}>
        <Contact />
      </div>
    </div>
  );
}

export default Newsletter;
