import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/Navbar.css";
import logo from "../Assets/images/img/mind_lab_logo.svg";

function Navbar() {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="nav_bar">
      <NavLink to="/" className="nav">
        <img src={logo} className="logo" alt=" Mind-Lab" />
      </NavLink>
      <div className="nav_menu">
        <div className="nav_links">
          <div className="nav_link">
            <NavLink className="nav" to="/">
              Home
            </NavLink>
          </div>
          <div className="nav_link">
            <NavLink className="nav" to="/newsletter">
              Newsletter
            </NavLink>
          </div>
          <div className="nav_link">
            <NavLink className="nav" to="/chatbot">
              
              Chatbot
            </NavLink>
          </div>
          
        </div>

        <div className="nav_toggle">
          <FontAwesomeIcon
            icon={toggle ? faTimes : faBars}
            className="nav_icon"
            onClick={() => setToggle((prev) => !prev)}
            color="blue"
          />
          <div className={`sidebar ${toggle ? "flex" : ""}`}>
            <div className="nav_links_mobile">
              <div className="nav_link">
                <NavLink className="nav" to="/">
                  Home
                </NavLink>
              </div>
              <div className="nav_link">
                <NavLink className="nav" to="/newsletter">
                  Newsletter
                </NavLink>
              </div>
              <div className="nav_link">
                <NavLink className="nav" to="/chatbot">
                  Chatbot
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
