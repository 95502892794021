import "./App.css";
import * as React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Home from "./Pages/Home";
import Newsletter from "./Pages/Newsletter";
import Chatbot from "./Pages/Chatbot";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path:"/newsletter",
    element:<Newsletter/>
  },
  {
    path:"/chatbot",
    element: <Chatbot/>
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
