import React from "react";
import Navbar from "../components/Navbar";
import styles from "../Styles/Home.module.css";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";
import serviceImg from "../Assets/images/img/pexels-alex-green-5699479.jpg";
import serviceImg2 from "../Assets/images/img/pexels-alex-green-5699455.jpg";
import serviceImg3 from "../Assets/images/img/pexels-cottonbro-4098152.jpg";

function Home() {
  return (
    <div className={styles.Home}>
      <Navbar />
      <div className={styles.hero_wrapper}>
        <div className={styles.content}>
          <p className={styles.header}>WELLCOME TO MINDLAB</p>
          <p className={styles.title}>Empowering mind's, Enriching lives</p>
          <p className={styles.text}>
            MindLab remains focused on next generation prevention (Next Gen): on
            the health and well-being of future generations, on systemic
            reforms, and on embracing new frontiers of science.
          </p>
        
        </div>
      </div>
      <div className={styles.about}>
        <div className={styles.about_content}>
          <p className={styles.header}> About Us</p>
          <p className={styles.title}>MindLab: Empowering Mental Wellbeing</p>
          <p className={styles.text}>
            MindLab is a safe, supportive space for individuals to explore their
            mental health. Our team of experienced professionals offers
            personalized coaching, group therapy, mindfulness, and cognitive
            behavioral therapy to help you achieve your wellbeing goals. Expert
            Support for: · Anxiety and Depression · Trauma and PTSD · Substance
            Abuse and Addiction · Relationship and Family Therapy · Career
            Development and Life Coaching Why Choose MindLab? • Confidentiality:
            We maintain the highest standards of confidentiality, ensuring that
            individuals feel safe sharing their concerns and struggles. •
            Flexibility: We offer flexible scheduling and online sessions to
            accommodate busy lifestyles. • Personalized Approach: Our experts
            tailor their approach to each individual's unique needs and goals.
          </p>
        </div>
      </div>
      <div className={styles.services}>
        <p className={styles.header}>Our Services</p>

        <div className={styles.services_content}>
          <div className={styles.card_wrapper}>
            <div className={styles.card_content}>
              <div className={styles.img_container}>
                <img className={styles.serviceImg} src={serviceImg} alt="" />
              </div>
              <p>Counseling and Therapy Services</p>
              <p>
                {" "}
                Provide professional one-on-one counseling sessions with
                licensed therapists, either in-person or online. These sessions
                can help individuals manage stress, anxiety, depression, and
                other mental health challenges through personalized guidance and
                therapeutic techniques like Cognitive Behavioral Therapy (CBT).
              </p>
            </div>
          </div>
          <div className={styles.card_wrapper}>
            <div className={styles.card_content}>
              <div className={styles.img_container}>
                <img className={styles.serviceImg} src={serviceImg2} alt="" />
              </div>
              <p> Support Groups</p>
              <p>
                Offer group therapy sessions or peer support groups for
                individuals dealing with similar mental health concerns (e.g.,
                anxiety, grief, addiction). These groups provide a safe space
                for sharing experiences, building community, and receiving
                emotional support from others with shared experiences.
              </p>
            </div>
          </div>
          <div className={styles.card_wrapper}>
            
            <div className={styles.card_content}>
            <div className={styles.img_container}>
                <img className={styles.serviceImg} src={serviceImg3} alt="" />
              </div>
              <p>Mental Health Workshops and Seminars</p>
              <p>
                Host workshops and seminars focusing on mental wellness topics,
                such as stress management, mindfulness, self-care, and coping
                strategies. These educational sessions can be offered to
                individuals, families, or workplaces to raise awareness and
                equip participants with practical tools to improve their mental
                well-being.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <Testimonials />
      </div>
      <div className={styles.contact}>
        <Contact />
      </div>
    </div>
  );
}

export default Home;
